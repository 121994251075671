// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-developpement-js": () => import("./../../../src/pages/developpement.js" /* webpackChunkName: "component---src-pages-developpement-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modern-contact-js": () => import("./../../../src/pages/modern-contact.js" /* webpackChunkName: "component---src-pages-modern-contact-js" */),
  "component---src-pages-our-solutions-js": () => import("./../../../src/pages/our-solutions.js" /* webpackChunkName: "component---src-pages-our-solutions-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projets-bosch-b-s-h-js": () => import("./../../../src/pages/projets/bosch-B-S-H.js" /* webpackChunkName: "component---src-pages-projets-bosch-b-s-h-js" */),
  "component---src-pages-projets-bosch-js": () => import("./../../../src/pages/projets/bosch.js" /* webpackChunkName: "component---src-pages-projets-bosch-js" */),
  "component---src-pages-projets-cosmos-catalogue-js": () => import("./../../../src/pages/projets/cosmos-catalogue.js" /* webpackChunkName: "component---src-pages-projets-cosmos-catalogue-js" */),
  "component---src-pages-projets-cosmos-js": () => import("./../../../src/pages/projets/cosmos.js" /* webpackChunkName: "component---src-pages-projets-cosmos-js" */),
  "component---src-pages-projets-dhj-js": () => import("./../../../src/pages/projets/dhj.js" /* webpackChunkName: "component---src-pages-projets-dhj-js" */),
  "component---src-pages-projets-live-project-js": () => import("./../../../src/pages/projets/live-project.js" /* webpackChunkName: "component---src-pages-projets-live-project-js" */),
  "component---src-pages-projets-livre-js": () => import("./../../../src/pages/projets/livre.js" /* webpackChunkName: "component---src-pages-projets-livre-js" */),
  "component---src-pages-projets-media-library-js": () => import("./../../../src/pages/projets/media-library.js" /* webpackChunkName: "component---src-pages-projets-media-library-js" */),
  "component---src-pages-projets-minyadi-js": () => import("./../../../src/pages/projets/minyadi.js" /* webpackChunkName: "component---src-pages-projets-minyadi-js" */),
  "component---src-pages-projets-precision-systems-js": () => import("./../../../src/pages/projets/precision-systems.js" /* webpackChunkName: "component---src-pages-projets-precision-systems-js" */),
  "component---src-pages-projets-sherbrooke-js": () => import("./../../../src/pages/projets/sherbrooke.js" /* webpackChunkName: "component---src-pages-projets-sherbrooke-js" */),
  "component---src-pages-projets-sodexo-js": () => import("./../../../src/pages/projets/sodexo.js" /* webpackChunkName: "component---src-pages-projets-sodexo-js" */),
  "component---src-pages-projets-sodexo-site-js": () => import("./../../../src/pages/projets/sodexo-site.js" /* webpackChunkName: "component---src-pages-projets-sodexo-site-js" */),
  "component---src-pages-projets-tacos-de-lyon-js": () => import("./../../../src/pages/projets/tacos-de-lyon.js" /* webpackChunkName: "component---src-pages-projets-tacos-de-lyon-js" */),
  "component---src-pages-transformation-innovation-js": () => import("./../../../src/pages/transformation-innovation.js" /* webpackChunkName: "component---src-pages-transformation-innovation-js" */),
  "component---src-pages-web-marketing-js": () => import("./../../../src/pages/web-marketing.js" /* webpackChunkName: "component---src-pages-web-marketing-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

