import {createStore, applyMiddleware, compose} from 'redux'
import reducer from "./reducers"
import thunk from 'redux-thunk'

export default function configureStore(initialState){
    return createStore(
        reducer,
        compose(
            applyMiddleware(thunk),
            typeof window !== "undefined" && window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );
}