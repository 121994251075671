import { reducer as formReducer } from 'redux-form';
import { combineReducers } from 'redux'
import {reducer as toastrReducer} from 'react-redux-toastr'

const initialState = {
  newsletterCompleted: true,
  newsletterSuccess: false,
  newsletterError: false,
  contactCompleted: true,
  contactSuccess: false,
  contactError: false
}

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NEWS_LETTER_SUCCESS':
      return {
        ...state,
        newsletterSuccess: action.state
      }
    case 'NEWS_LETTER_ERROR':
      return {
        ...state,
        newsletterError: action.state
      }
    case 'NEWS_LETTER_COMPLETED':
      return {
        ...state,
        newsletterCompleted: action.state
      }
    case 'CONTACT_SUCCESS':
      return {
        ...state,
        contactSuccess: action.state
      }
    case 'CONTACT_ERROR':
      return {
        ...state,
        contactError: action.state
      }
    case 'CONTACT_COMPLETED':
      return {
        ...state,
        contactCompleted: action.state
      }
    default:
      return state
  }
}

export default combineReducers({
  contactReducer,
  form: formReducer,
  toastr: toastrReducer
})