/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// require ("./src/scripts/jquery.min.js")
// require ("./src/scripts/jquery.pagepiling.min.js")
// You can delete this file if you're not using it

// import "./src/styles/style.min.css"
import "./src/styles/style.min0be4.css"
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import helpers from './src/scripts/helpers';
import wrapWithProvider from "./wrap-with-provider"

if(typeof document !== "undefined") var $ = require("jquery")
// var initial = false

export const wrapRootElement = wrapWithProvider

export const onPreRouteUpdate = ({ location, prevLocation }) => {
    helpers.initSmoothScroll()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
    if(window && $("#wall").length > 0){
        helpers.initPagePilling()
    } else {
        helpers.unloadCSS("#pagepilling")
        // $("body").css("overflow-y", "auto")
        $("html").css("scroll-behavior", "smooth")
        $("html").css("overflow-y", "auto")
    }
}

export const onInitialClientRender = () => {

    $(window).bind("load", function() {
        // $(".loader").fadeIn().delay(800).fadeOut();
        // initial = true
    });

    $(document).on("click", ".hamburger", function() {
		$(this).toggleClass("hamburger--active");
		$(".page-head").toggleClass("page-head--open");
		if ($(".call-to-action") !== null) $(".call-to-action").toggleClass("call-to-action--alt");
    })

}
