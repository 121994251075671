// import $ from 'jquery';
import cssStyle from "../styles/pagepiling.css"

if(typeof document === "undefined"){
	// var jsdom = require("jsdom");
	// var $ = require("jquery")(jsdom.jsdom().createWindow());
} else {
	var $ = require("jquery")
}

const herlpers = {
	validateRequire: (value) => {
		if (value && value != "") return true
		else return false
	},
	validateEmail: (value) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(value).toLowerCase());
	},
	validatePhoneNumber: (value) => {
		var re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
		return re.test(String(value).toLowerCase());
	},
	initSmoothScroll: () => {
		// Scroll to specific values
		// scrollTo is the same
		window.scroll({
			top: 2500,
			left: 0,
			behavior: 'smooth'
		});

		// Scroll certain amounts from current position
		window.scrollBy({
			top: 100, // could be negative value
			left: 0,
			behavior: 'smooth'
		});

		// Scroll to a certain element
		document.querySelector('body').scrollIntoView({
			behavior: 'smooth'
		});
	},
	loadCSS: (str, hash = "default") => {
		var node = document.createElement('style');
		node.id = hash;
		node.innerHTML = str;
		document.body.appendChild(node);
	},
	unloadCSS: (hash) => {
		var cssNode = document.getElementById(hash);
		cssNode && cssNode.parentNode.removeChild(cssNode);
	},
	initCallToActionBanner: () => {

		$.fn.isOnScreen = function(){
			var win = $(window);
			var viewport = {
				top : win.scrollTop(),
				left : win.scrollLeft()
			};
			viewport.right = viewport.left + win.width();
			viewport.bottom = viewport.top + win.height();
			var bounds = this.offset();
			bounds.right = bounds.left + this.outerWidth();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		}

		$(document).ready(function() { //<<====== wont work without this
				var $sticky = $("#sticky-banner")
				var $demand = $("#demand-banner")
				if($sticky.length > 0 && $demand.length > 0) {
					$("body").scroll(function() {
						if( $demand.isOnScreen() ) {
							$demand.fadeIn()
							$sticky.fadeOut()
						} else {
							$sticky.fadeIn()
						}
					});
				}
		});

	},
	getParameterByName: (name, url) => {
		if (!url) url = typeof window !== "undefined" ? window.location.href : "";
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	},
	initPagePilling: () => {

		window.jQuery = $;
		require("pagepiling.js")
		herlpers.loadCSS(cssStyle, "#pagepilling")

		var $pageHead = $('.page-head.page-head--no-scroll-listen'),
		$callToAction = $pageHead.find('.call-to-action'),
		$cassettes = $('.scroll-jacker__cassette'),
		cassetteCount = $cassettes.length - 1;

		$('#wall').pagepiling({
			menu: null,
			direction: 'vertical',
			scrollingSpeed: 700,
			easing: 'swing',
			loopBottom: false,
			loopTop: false,
			css3: true,
			navigation: false,
			normalScrollElements: null,
			normalScrollElementTouchThreshold: 5,
			touchSensitivity: 5,
			keyboardScrolling: true,
			sectionSelector: '.scroll-jacker__cassette',
			animateAnchor: false,
			onLeave: function (index, nextIndex, direction) {
				if(direction == 'down' && index == cassetteCount) {
					$pageHead.removeClass('page-head--no-start-bg');
					$callToAction.addClass('call-to-action--alt');
				} else if(direction == 'up' && index == cassetteCount + 1) {
					$pageHead.addClass('page-head--no-start-bg');
					$callToAction.removeClass('call-to-action--alt');
				}
			},
			afterRender: function() {
				$(document).on('click', '.hero__chevron-south', function() {
						console.log("ouch !!!")
						$.fn.pagepiling.moveSectionDown();
					}
				);

			}
		});

	},
	runParallaxEffect: () => {

		var mobileMql = window.matchMedia('(min-width: 1200px)'),
				parallaxSpeedDefault = 0.15,
				timer = 0,
				scrollListeningInterval = 15,
				scrolledDown = 0,
				documentHeight = Math.max(
			window.innerHeight,
			document.body.offsetHeight,
			document.documentElement.clientHeight
			),
				parallaxes = document.querySelectorAll('.parallax'),
				generalScrollHandler = function generalScrollHandler() {

					scrolledDown = document.body.scrollTop;

					for(var i = 0; i < parallaxes.length; i++) {
						handleParallaxScroll(parallaxes[i]);
					}
				},
				handleParallaxScroll = function handleParallaxScroll(parallax) {

					var diff = parallax.getBoundingClientRect().top + document.body.scrollTop - scrolledDown;

					if(Math.abs(diff) < documentHeight) {

						if(!parallax.horizontal) {
							// parallax.layers[1].style.top = (diff * .15) + 'px';
							parallax.layers[1].style.top = (diff * parallax.speed) + 'px';

							if(parallax.layers[2]) {
								// parallax.layers[2].style.top = (diff * .25) + 'px';
								parallax.layers[2].style.top = (diff * parseFloat(parallax.speed + 0.10)) + 'px';
							}
						} else {
							// parallax.layers[1].style.top = (diff * .15) + 'px';
							parallax.layers[1].style.left = (diff * parallax.speed) + 'px';

							if(parallax.layers[2]) {
								// parallax.layers[2].style.left = (-diff * .05) + 'px';
								parallax.layers[2].style.left = (-diff * parseFloat(parallax.speed - .10)) + 'px';
							}
						}
					} else if(Math.abs(diff) < documentHeight / 3) {

						if(!parallax.horizontal) {
							// parallax.layers[1].style.top = (diff * .25) + 'px';
							parallax.layers[1].style.top = (diff * parseFloat(parallax.speed + 0.10)) + 'px';

							if(parallax.layers[2]) {
								// parallax.layers[2].style.top = (diff * .45) + 'px';
								parallax.layers[2].style.top = (diff * parseFloat(parallax.speed + 0.30)) + 'px';
							}
						}	else {
							// parallax.layers[1].style.left = (diff * .25) + 'px';
							parallax.layers[1].style.left = (diff * parseFloat(parallax.speed + 0.10)) + 'px';

							if(parallax.layers[2]) {
								// parallax.layers[2].style.left = (-diff * .05) + 'px';
								parallax.layers[2].style.left = (-diff * parseFloat(parallax.speed - .10)) + 'px';
							}
						}
					}
				};


		if(mobileMql.matches) {

			// pre-fetch parallaxed layers and other data so we won't have to redo this on every scroll event trigger
			for(var i = 0; i < parallaxes.length; i++) {
				var layers = parallaxes[i].querySelectorAll('.parallax__layer'),
						index = 0;

				parallaxes[i].layers = [];
				parallaxes[i].horizontal = parallaxes[i].classList.contains('parallax--horizontal');

				if(parallaxes[i].dataset.parallaxSpeed !== undefined) {
					parallaxes[i].speed = Number.parseFloat(parallaxes[i].dataset.parallaxSpeed);
				} else {
					parallaxes[i].speed = parallaxSpeedDefault;
				}

				for(var j = 0; j < layers.length; j++) {
					index = layers[j].className.match(/(parallax__layer)--(\d)/)[2];

					if(index !== null) {
						parallaxes[i].layers[index] = layers[j];
					}
				}
			}

			window.addEventListener(
				'scroll',
				function() {
					if(timer) {
						window.clearTimeout(timer);
					}
					timer = window.setTimeout(generalScrollHandler, scrollListeningInterval);
				}
			);
		}
	}

}

export default herlpers